import styled from "styled-components";
import colors from "../../styles/colors";

export const Section = styled.div<any>`
  background-color: ${(props) => (props.color ? props.color : "none")};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  height: 515px;
  &:last-child {
    margin: 30px 0 110px 0;
  }
  @media (max-width: 768px) {
    height: unset;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const SideBar = styled.div`
  background-color: ${colors.primary};
  width: 90px;
  flex-basis: 90px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 20px 0 208px 0;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    height: 17px;
    width: 17px;
    cursor: pointer;
    margin: 35px 0;
  }

  img:hover {
    height: 22px;
    width: 22px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 200px;
  overflow: scroll;
`;

export const FirstBlock = styled.div`
  iframe {
    border: none;
    padding: 0 60px;

    @media (max-width: 768px) {
      padding: 20px;
      width: 90%;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const CarouselSection = styled.div`
  padding: 10px 60px;
`;

export const ImageBlock = styled.img<any>`
  height: ${(props) => (props.height ? props.height : "515px")};

  @media (max-width: 768px) {
    height: unset;
    width: 100%;
  }
`;
