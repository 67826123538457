import React from "react";
import { Link } from "react-scroll";
import {
  Buttons,
  Container,
  LeftSide,
  RightSide,
  Logo,
  ExternalLink,
} from "./Styles";

const Header = () => {
  return (
    <Container>
      <LeftSide>
        <Logo>
          <h2>V e B</h2>
        </Logo>
      </LeftSide>
      <RightSide>
        <Buttons>
          <ul>
            <li>Sobre nós</li>
            <li>
              <ExternalLink
                href="https://sites.icasei.com.br/brunovanessa2022"
                target="_blank"
              >
                {" "}
                Lista de presentes
              </ExternalLink>
            </li>
            {/*<li>+ Bruno</li>*/}
            {/*<li>+ Vanessa</li>*/}
            <li>
              <Link
                activeClass="active"
                to="2em1"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                {" "}
                2 em 1
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="info"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Informações
              </Link>
            </li>
          </ul>
        </Buttons>
      </RightSide>
    </Container>
  );
};

export default Header;
