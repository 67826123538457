import React from "react";
import { PageWrapper } from "../../styles/globals";
import {
  CarouselSection,
  Section,
  SideBar,
  Content,
  ImageBlock,
  FirstBlock,
} from "./Styles";
import Slider from "react-slick";
import TextBlock from "../../components/TextBlock/TextBlock";
import {
  TitleBlock,
  WrapperRow,
  ButtonLink,
  ButtonArea,
  TextWrapper,
} from "../../components/TextBlock/Styles";
import pic1 from "../../images/veb1.jpeg";
import pic2 from "../../images/veb2.jpeg";
import pic12 from "../../images/pic12.jpg";
import pic11 from "../../images/pic11.jpg";
import pic10 from "../../images/pic10.jpg";
import pic9 from "../../images/pic9.jpeg";
import pic8 from "../../images/pic8.jpg";
import pic7 from "../../images/pic7.jpg";
import pic6 from "../../images/pic6.jpeg";
import pic5 from "../../images/pic5.jpeg";
import pic4 from "../../images/pic4.jpeg";
import pic3 from "../../images/veb3.jpeg";
import gift from "../../images/gift.png";
import gps from "../../images/gps.png";
import instagram from "../../images/instagram.png";
import whatsapp from "../../images/whatsapp.png";
import colors from "../../styles/colors";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <PageWrapper>
      <SideBar>
        <a
          href="https://sites.icasei.com.br/brunovanessa2022"
          target="_blank"
          rel="noreferrer"
        >
          <img src={gift} alt="icon" />
        </a>
        <a
          href="https://goo.gl/maps/PjR86GAeBhfhzeH38"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="icon" />
        </a>
        <a
          href="https://goo.gl/maps/PjR86GAeBhfhzeH38"
          target="_blank"
          rel="noreferrer"
        >
          <img src={gps} alt="icon" />
        </a>
        <a
          href="https://goo.gl/maps/PjR86GAeBhfhzeH38"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="icon" />
        </a>
      </SideBar>
      <Content>
        <Section>
          <FirstBlock>
            <ImageBlock src={pic2} />
          </FirstBlock>
          <TextBlock
            key={1}
            title={"Vanessa & Bruno"}
            text={
              "É com grande alegria que convidamos nossos queridos familiares e amigos para a consagração da nossa união. Está chegando o momento que Deus reservou para nós. Esperamos que seja um momento cheio da presença de Deus e marcante, pois todos vocês fazem parte da nossa história."
            }
          />
        </Section>
        <Section color={colors.blue2}>
          <TextBlock
            key={2}
            title={"Encontrados no desencontro"}
            color={colors.white}
            bgColor={colors.blue2}
            titleColor={colors.white}
            fontSize={"3.5rem"}
            image={pic1}
            text={
              "Tudo começou na primeira quinzena de Abril de 2021, quando se conheceram nas redes sociais. Foram muitas horas de conversas, do nascer do Sol: quando a Vanessa estava voltando do trabalho e o Bruno acordando, e até altas horas quando Bruno já tinha parado de trabalhar e a Vanessa estava indo ao trabalho. A Vanessa, já estava decidida a mudar para Natal. Bruno naquele momento estava num momento de reflexão, com a crise da covid que impossibilitou muitos projetos pessoais. Ainda assim, Vanessa foi se interassando cada vez mais pelo Bruno. Fez às unhas e o cabelo para sair e conhecê-lo pessoalmente. Foi na passarela do Shopping Tatuapé que os dois olhares se cruzaram pela primeira vez. [Continua abaixo]"
            }
          />
        </Section>
        <Section>
          <WrapperRow bgColor={colors.primary} width="100%">
            <TitleBlock width={"40%"} fontSize={"3.5rem"}>
              Lista de presentes
            </TitleBlock>
            <TextWrapper>
              Criamos nossa lista pelo icasei, onde é feito a compra e
              pagamento.
            </TextWrapper>
            <ButtonArea>
              <ButtonLink
                href="https://sites.icasei.com.br/brunovanessa2022"
                target="_blank"
              >
                <ImageBlock src={gift} height="18px" />
                Ver presentes
              </ButtonLink>
            </ButtonArea>
          </WrapperRow>
        </Section>{" "}
        <Section>
          <FirstBlock>
            <ImageBlock src={pic3} />
          </FirstBlock>
          <TextBlock
            id="2em1"
            name="2em1"
            key={4}
            title={"2 em 1"}
            color={colors.black}
            bgColor={colors.primary}
            titleColor={colors.black}
            fontSize={"3.5rem"}
            text={
              'Ele estava bem ansioso, na tentativa de se sentir mais aliviado, deu um abraço nela, e ela não reagiu, ficou imobilizada. O que passava na cabeça da Vanessa era o questionamento: "Será que ele já gosta tanto assim de mim para ficar tão ansioso?”. Naquele momento Bruno só queria se acalmar um pouco para conhecer um pouco sobre a Vanessa. Dali foram para a padaria chamada Big Bread (único local aberto que o Bruno lembrou naquele momento de restrição). Era uma noite fria, e ao chegar ao local, quando começou a falar, Bruno ficou mais calmo. Eles conversaram por horas. Depois daquele dia, não pararam de se encontrar. O sentimento e carinho foi crescendo cada vez mais. E não demorou muito para o pedido de namoro oficial chegar.'
            }
          />
        </Section>{" "}
        <Section>
          <TextBlock
            key={4}
            color={colors.black}
            bgColor={colors.primary}
            titleColor={colors.black}
            text={
              'Dia 3 de Junho. A partir daí passaram a sonhar juntos. Desde o começo compartilharam sonhos, angústias e objetivos. A volta permanente para Natal de Vanessa foi deixada de lado. Os sonhos se somaram e cada um passou a ter o sonho do outro para si. “Nós”, já não eramos mais "eu". Foram muitas catracas giradas de metrô juntos. A Vanessa trabalhando de madrugada no início do namoro e o Bruno de dia. A forma de se verem era acompanha-lá do metrô Tatuapé até a estação trianon-masp, mas esse pequeno tempo, com direito a alguns cochilos da Vanessa dentro do metrô, era especial. Naquele período foi quando Bruno deu o apelido de "pandinha" para Vanessa, por conta das olheiras do trabalho da madrugada. Bruno começou a dividir, seus projetos profissionais, vida pessoal e o relacionamento dele. Estar por perto sempre representou muito para eles. O brilho no olhar e a vontade de fazer dar certo sempre estiveram presentes.'
            }
          />
        </Section>{" "}
        <CarouselSection>
          <Slider {...settings}>
            <div>
              <ImageBlock src={pic12} />
            </div>
            <div>
              <ImageBlock src={pic11} />
            </div>
            <div>
              <ImageBlock src={pic10} />
            </div>
            <div>
              <ImageBlock src={pic7} />
            </div>
            <div>
              <ImageBlock src={pic8} />
            </div>
            <div>
              <ImageBlock src={pic9} />
            </div>{" "}
            <div>
              <ImageBlock src={pic4} />
            </div>
            <div>
              <ImageBlock src={pic5} />
            </div>
            <div>
              <ImageBlock src={pic6} />
            </div>
          </Slider>
        </CarouselSection>
        <Section>
          <TextBlock
            key={4}
            color={colors.black}
            bgColor={colors.primary}
            titleColor={colors.black}
            text={
              "Desde sempre a paz de cristo em seus corações foi muito presente. Deus confirmou de diversas formas e vezes, de que o relacionamento é da vontade dele. Em uma conversa informal em janeiro de 2022, alinhando alguns sonhos e objetivos, perceberam que esse é o momento ideal para juntarem as escovas. Oraram por isso e Deus confirmou novamente. Hoje falta pouco para a matemática 1+1 ser igual a 1. Uma nova família vai nascer. Um lar abençoado está se formando. É apenas o início do que Deus tem preparado. Confiam que a sua bondade e fidelidade vai além. Assim começa essa nova fase. Contamos com vocês para testemunhar como Deus é bom! Aguarde! 29 de Maio vem aí e você já faz parte desse momento!"
            }
          />
        </Section>{" "}
        <Section>
          <TextBlock
            key={5}
            title={"O que você não pode esquecer"}
            color={colors.black}
            bgColor={colors.primary}
            titleColor={colors.black}
            fontSize={"3.5rem"}
            text={
              "A cerimônia começa as 10h50. Será realizada no Monte Millazzo: Rua Paulo Barbosa, 290 - Jardim Arujá, Arujá. Estacionamento pago no local."
            }
          />
          <FirstBlock>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14649.343829813159!2d-46.3085915!3d-23.3760606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8fc9c9bfdc4e6eaa!2sMonte%20Millazzo!5e0!3m2!1spt-BR!2sbr!4v1648326608104!5m2!1spt-BR!2sbr"
              width="500"
              height="515"
              loading="lazy"
              title="map"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </FirstBlock>
        </Section>
      </Content>
    </PageWrapper>
  );
};

export default Home;
