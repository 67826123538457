import React from "react";
import { Wrapper, TitleBlock, Text, ImageBlock } from "./Styles";

interface TextBlockProps {
  id?: string;
  name?: string;
  title?: string;
  text: string;
  color?: string;
  bgColor?: string;
  titleColor?: string;
  fontSize?: string;
  image?: string;
}

const TextBlock = (props: TextBlockProps) => {
  const { title, text, image, id, name } = props;
  return (
    <Wrapper bgColor={props.bgColor} hasImage={image}>
      <div>
        {title && (
          <TitleBlock
            id={id}
            name={name}
            fontSize={props.fontSize}
            titleColor={props.titleColor}
          >
            {title}
          </TitleBlock>
        )}
        <Text color={props.color}>{text}</Text>
      </div>
      {image && <ImageBlock src={image} height="370" alt="pic" />}
    </Wrapper>
  );
};

export default TextBlock;
