import styled from "styled-components";
import colors from "../../styles/colors";

export const Wrapper = styled.div<any>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colors.primary};
  width: ${(props) => (props.width ? props.width : "unset")};
  padding: 60px;
  display: flex;
  flex-direction: ${(prop) => (prop.hasImage ? "row" : "column")};
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: ${(prop) => (prop.hasImage ? "5px" : "unset")};

  @media (max-width: 768px) {
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 0;
  }
`;

export const WrapperRow = styled.div<any>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colors.primary};
  width: ${(props) => (props.width ? props.width : "unset")};
  padding: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 768px) {
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
  }
`;

export const TitleBlock = styled.h1<any>`
  line-height: 94px;
  width: ${(props) => (props.width ? props.width : "100%")};
  color: ${(props) => (props.titleColor ? props.titleColor : colors.black)};
  text-transform: capitalize;
  margin-top: 10px;
  font-weight: 800;
  font-family: "Arapey", serif;
  letter-spacing: 0;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "5.4rem")};
  text-align: start;

  @media (max-width: 768px) {
    margin-bottom: 15px !important;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 2.8rem;
  }
`;

export const ImageBlock = styled.img`
  padding-left: 60px;
  
  @media (max-width: 768px) {
    padding: 20px;
    height: 270px;
  }
`;

export const Text = styled.span`
  margin-top: 15px;
  color: ${(props) => (props.color ? props.color : colors.black)};
  font-weight: 300;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    text-align: center;
    line-height: 28px;
    font-size: 1.2rem;
  }
`;

export const TextWrapper = styled.span`
  color: ${(props) => (props.color ? props.color : colors.black)};
  font-weight: 300;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
    height: unset;
    font-size: 1.2rem;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 200px;
  height: 100%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
    height: unset;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    a {
      margin: 0;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const ButtonLink = styled.a`
  margin-left: 6px;
  border: ${colors.blue};
  border-style: solid;
  border-width: 1px;
  font-size: 1rem;
  color: ${colors.black};
  background-color: ${colors.primary};
  padding: 8px 10px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 600;
  height: 20px;
  width: 164px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${colors.blue};
    color: ${colors.black};
    font-weight: 400;
  }
  img {
    margin-right: 6px;
  }
`;
