import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  grid-area: header;
  background-color: ${colors.primary};
`;

export const RightSide = styled.div`
  padding: 14px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 70%;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 2px 0;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 90px;
  flex-basis: 90px;
  @media (max-width: 768px) {
    width: 70px;
    flex-basis: 70px;
  }
`;

export const Logo = styled.div`
  background-color: ${colors.blue};
  width: 90px;
  font-family: Buffalo, "Great Vibes", serif;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: ${colors.white};
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    width: 60px;
    height: 48px;
    h2 {
      font-size: 1rem;
    }
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.gray};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: nowrap;
    color: ${colors.gray2};
    column-gap: 80px;

    li {
      cursor: pointer;
      &:last-child {
        margin-right: 70px;
      }
      &:first-child {
        text-decoration: underline ${colors.blue2};
        margin-left: 20px;
      }
      &:hover {
        color: ${colors.blue2};
      }
    }

    @media (max-width: 768px) {
      column-gap: 0;
      justify-content: space-evenly;
      margin: 0;
      font-size: 0.8rem;
      
      li {
        margin: 0;
      }

      li:nth-child(4) {
        display: none;
      }
    }
    @media (max-width: 480px) {
      li:nth-child(3) {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    column-gap: 0;
    justify-content: stretch;
  }
`;
