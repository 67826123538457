import React from 'react';
import {   FooterContainer, Wrapper } from './Styles';
import Detail from '../Detail';

const Footer = () => {
  return (
    <Wrapper>
      <Detail />
      <FooterContainer>
        <a href="https://github.com/brds15" rel="noreferrer" target="_blank">Desenvolvido pelo noivo</a>
      </FooterContainer>
    </Wrapper>
  );
};

export default Footer;
